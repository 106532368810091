import initBusinessQuestionModule from "../components/BusinessQuestion";
import { initEditDropdowns } from "../components/EditMenuDropdowns";
import initExpander from "../components/Expander";
import { NavigationTabBarSinglePage } from "../components/NavigationTabs";
import initSuccessModal from "../components/SuccessModal";
import { initEditableUserPosts, initUserPostForms } from "../components/UserPost";
import initReCAPTCHAs from "../core/reCAPTCHA";
import {
  createFunctionWithTimeout,
  scrollToElement,
  trackCustomUserAction,
} from "../core/utils";
import { initBusinessPromosDrawer } from "./utils";

export default function initBusiness(props) {
  new NavigationTabBarSinglePage(document.querySelector(".nav-tab-container"));

  const searchParams = new URLSearchParams(window.location.search);

  // handle tracking for form submit

  const form = document.getElementsByClassName("review-form")[0];

  form.addEventListener("submit", (event) => {
    event.preventDefault();

    const callback = createFunctionWithTimeout(() => {
      form.submit();
    });

    trackCustomUserAction(
      window.location.href,
      props.tracking.category,
      "submit-review",
      callback,
    );
  });

  const visitWebsiteElement = document.querySelector(".visit-website");
  if (visitWebsiteElement) {
    visitWebsiteElement.addEventListener("click", () => {
      trackCustomUserAction(
        window.location.href,
        props.tracking.category,
        props.tracking.visitWebsite,
      );
    });
  }

  const businessQuestionProps = {
    questionSent: searchParams.get("question-sent"),
    ...props,
  };
  initBusinessQuestionModule(businessQuestionProps);

  const sendFlowersAboutElement = document.querySelector(
    ".send-flowers-about-section-link",
  );
  if (sendFlowersAboutElement) {
    sendFlowersAboutElement.addEventListener("click", () => {
      trackCustomUserAction(
        window.location.href,
        props.tracking.category,
        props.tracking.sendFlowersAbout,
      );
    });
  }

  document
    .querySelectorAll(".column-section__business--flowers a")
    .forEach((anchorElement) => {
      anchorElement.addEventListener("click", () => {
        trackCustomUserAction(
          window.location.href,
          props.tracking.category,
          props.tracking.sendFlowersRightRail,
        );
      });
    });

  initSuccessModal();

  document.querySelectorAll(".review-text .expandable").forEach((userPost) => {
    initExpander(userPost);
  });

  if (window.location.hash === "#leave-review") {
    scrollToElement(document.querySelector(".leave-review"), -40);
  }

  if (window.location.hash === "#to-reviews") {
    scrollToElement(document.querySelector(".business-reviews-container"));
  }

  window.initReCAPTCHAs = initReCAPTCHAs;

  if (props.scrollToQuestion) {
    const questionElement = document.querySelector(
      `[data-question-uuid="${props.scrollToQuestion}"`,
    );
    if (questionElement) {
      scrollToElement(questionElement, -100);
    }
  }

  if (window.location.hash === "#q-a") {
    scrollToElement(document.querySelector(".business-q-a-wrapper"), -140);
  }

  initBusinessPromosDrawer();
  initEditableUserPosts(props);
  initEditDropdowns();
  initUserPostForms();
}
