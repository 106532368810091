import TomSelect from "tom-select/dist/js/tom-select.base";
import TomSelect_virtual_scroll from "tom-select/dist/js/plugins/virtual_scroll";

import requests, { ResponseError } from "../core/requests";
import { createAutocompleteOption } from "../places/utils";

TomSelect.define("virtual_scroll", TomSelect_virtual_scroll);

/**
 * Format a community organization for display in the TomSelect results list.
 *
 * @param {object} organization - the organization data from the backend
 * @returns {HTMLDivElement} - the formatted DIV for the results list.
 */
function formatOrganization(organization) {
  const subtitleLines = [];

  if (organization.religionDenomination) {
    subtitleLines.push(
      `${organization.religionDenomination} ${organization.organizationType}`,
    );
  } else if (organization.organizationType) {
    subtitleLines.push(organization.organizationType);
  }

  if (organization.address) {
    subtitleLines.push(organization.address);
  }

  return createAutocompleteOption(organization.text, ...subtitleLines);
}

/**
 * Initialize the community org autocomplete search with TomSelect.
 *
 * @param {object} props - the autocomplete props as generated by the backend.
 */
export default function initCommunityOrganizationAutocomplete(props) {
  const organizationIdentifierInput = document.getElementById(props.identifierInputId);

  const buildUrl = (query, page) => {
    const orgType = (
      document.querySelector('input[name="affiliation-organization_type"]:checked') || {
        value: -1,
      }
    ).value;
    const paramsData = {
      q: query,
      t: orgType,
      page,
    };

    const params = new URLSearchParams(paramsData);

    return `${props.searchUrl}?${params.toString()}`;
  };

  const tomSelectConfig = {
    plugins: ["virtual_scroll"],
    valueField: "id",
    sortField: [{ field: "$order" }, { field: "$score" }],
    searchField: [],
    firstUrl(query) {
      return buildUrl(query, 1);
    },
    async load(query, callback) {
      try {
        const response = await requests.get(this.getUrl(query));

        if (!response.ok) {
          throw new ResponseError(response);
        }

        const data = await response.json();

        if (data.pagination.next) {
          this.setNextUrl(query, buildUrl(query, data.pagination.next));
        }

        callback(data.results);
      } catch (error) {
        if (!(error.response && error.response.status === 429)) {
          window.Rollbar.error("TomSelect community org search error", error);
        }

        callback();
      }
    },
    loadThrottle: 500,
    placeholder: props.organizationPlaceholder,
    render: {
      option: (item) => formatOrganization(item),
    },
    create: (input) => ({ id: input, text: input, created: true }),
    maxItems: 1,
  };

  const organizationSelect = new TomSelect(
    organizationIdentifierInput,
    tomSelectConfig,
  );

  // Set the value, creating a new option if necessary
  if (props.initial && props.initial.id) {
    const availableOption = organizationSelect.options[props.initial.id];
    if (!availableOption) {
      organizationSelect.addOption(props.initial);
    }

    organizationSelect.setValue(props.initial.id);
  }
}
