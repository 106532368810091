import Collapse from "bootstrap/js/dist/collapse";

import { generateElements, initTooltips, trackCustomUserAction } from "../core/utils";

const USER_CONTRIBUTED_PRICE_TOOLTIP_COPY =
  "This is an estimate based on user-submitted and/or publicly available data. It was not submitted by the business. Contact the business to confirm.";
const ESTIMATED_PRICE_TOOLTIP_COPY =
  "This is a typical price that is not specific to this business. Contact the business for actual pricing.";

/**
 * Toggle the description for a line item associated with a given event.
 *
 * @param {Event} event - the event calling this function
 */
const toggleDescription = (event) => {
  Collapse.getOrCreateInstance(event.currentTarget.querySelector(".pricing-tip"), {
    toggle: false,
  }).toggle();
  event.currentTarget.querySelector(".price-row-caret").classList.toggle("rotated");
};

/**
 * Initialize the JavaScript for a FuneralHome price list.
 *
 * NOTE: Currently requires caretPath, grayCaretPath and blueCirclePath and
 * yellowCirclePath to be in props.
 *
 * @param {object} props - the price list props
 */
export default function initFuneralHomePriceList(props) {
  if (!props.priceListTable) {
    return;
  }

  const services = { ...props.originalServices };
  const estimatedPricesStatement = props.priceListTable.parentElement.querySelector(
    ".estimated-prices-statement",
  );
  const tableFilters = props.priceListTable.parentElement.querySelector(
    ".price-list-service-filters",
  );

  function updateLegend() {
    document
      .querySelector(".price-list-legend-average-pricing")
      .classList.toggle(
        "d-none",
        document.querySelectorAll(".price-indicator-estimate").length === 0,
      );

    document
      .querySelector(".price-list-legend-user-contributed")
      .classList.toggle(
        "d-none",
        document.querySelectorAll(".price-indicator-user-contributed").length === 0,
      );
  }

  function updateServices(className, selectedValue) {
    if (className === "price-list-disposition") {
      if (selectedValue === "burial") {
        services.burial = true;
        services.cremation = false;
        services.body_composting = false;
        services.liquid_cremation = false;
      } else if (selectedValue === "body-composting") {
        services.burial = false;
        services.cremation = false;
        services.body_composting = true;
        services.liquid_cremation = false;
        services.embalming = false;
        const embalmingSelect = document.querySelector(".price-list-embalming");
        if (embalmingSelect) {
          embalmingSelect.value = "no-embalming";
        }
      } else if (selectedValue === "liquid-cremation") {
        services.burial = false;
        services.cremation = false;
        services.body_composting = false;
        services.liquid_cremation = true;
        services.embalming = false;
        const embalmingSelect = document.querySelector(".price-list-embalming");
        if (embalmingSelect) {
          embalmingSelect.value = "no-embalming";
        }
      } else {
        services.burial = false;
        services.cremation = true;
        services.body_composting = false;
        services.liquid_cremation = false;
      }
    } else if (className === "price-list-embalming") {
      if (selectedValue === "embalming") {
        services.embalming = true;
      } else {
        services.embalming = false;
      }
    } else if (className === "price-list-viewing") {
      if (selectedValue === "viewing") {
        services.viewing = true;
      } else {
        services.viewing = false;
      }
    } else if (className === "price-list-service") {
      if (selectedValue === "traditional-service") {
        services.service = true;
        services.independentEvent = false;
      } else if (selectedValue === "independent-event") {
        services.service = false;
        services.independentEvent = true;
      } else {
        services.service = false;
        services.independentEvent = false;
      }
    }
  }

  function updateEstimatedPricesStatement(priceListTable) {
    const isOfficial = priceListTable.classList.contains("prices-edited-table");
    let service;
    if (services.service) {
      service = "a Traditional Service";
    } else if (services.independentEvent) {
      service = "an Independent Event";
    } else {
      service = "no Service";
    }

    let dispositionType;
    if (services.burial) {
      dispositionType = "Burial";
    } else if (services.cremation) {
      dispositionType = "Cremation";
    } else if (services.liquid_cremation) {
      dispositionType = "Liquid Cremation";
    } else if (services.body_composting) {
      dispositionType = "Natural Organic Reduction";
    }

    const statement = `${isOfficial ? "Official" : "Estimated"} prices for a ${dispositionType} with ${services.viewing ? "a" : "no"} Viewing, ${services.embalming ? "" : "no"} Embalming, and ${service}`;

    estimatedPricesStatement.innerText = statement;
  }

  function insertPriceTableRow(priceListTable, field, fieldMax) {
    const isOfficial = priceListTable.classList.contains("prices-edited-table");
    const price =
      field.price > 0
        ? `$${new window.Intl.NumberFormat("US").format(field.price)}`
        : "N/A";
    const caretPath = price === "N/A" ? props.grayCaretPath : props.caretPath;
    const fieldHasMax = fieldMax && fieldMax.price > 0;
    const fieldMaxValue = fieldHasMax
      ? ` - $${new window.Intl.NumberFormat("US").format(fieldMax.price)}`
      : "";

    const rowClass = price === "N/A" ? ' class="not-available"' : "";
    const secondaryCellClass =
      field.using_average_price || field.user_contributed ? "" : ' class="circle-less"';
    let imageHtml = "";
    if (!isOfficial && field.using_average_price) {
      imageHtml = `<img class="price-indicator price-indicator-estimate" src="${props.blueCirclePath}">`;
    } else if (!isOfficial && field.user_contributed) {
      imageHtml = `<img class="price-indicator price-indicator-user-contributed" src="${props.yellowCirclePath}">`;
    }

    // price row without product link
    priceListTable.querySelector("tbody").append(
      ...generateElements(
        `<tr${rowClass}>
          <td class="pricing-primary-cell">
            <div class="pricing-title">
              <div>
                <img class="price-row-caret" src="${caretPath}" width="9" height="15" alt="Right caret">
                ${field.title}
              </div>
            </div>
            <div class="pricing-tip collapse">${field.tip}</div>
          </td>
          <td class="pricing-secondary-cell">
            <div${secondaryCellClass}>
              ${imageHtml}
              ${price}${fieldMaxValue}
            </div>
          </td>
        </tr>`,
      ),
    );

    // add a marketplace pricing row when needed
    if (!isOfficial && field.marketplace_data) {
      const minPrice = `$${new window.Intl.NumberFormat("US").format(field.marketplace_data.min_price)}`;
      const maxPrice = `$${new window.Intl.NumberFormat("US").format(field.marketplace_data.max_price)}`;
      priceListTable.querySelector("tbody").append(
        ...generateElements(
          `<tr class="marketplace-pricing-row">
            <td class="pricing-primary-cell">
              <div class="pricing-marketplace-message">
                Save!
                <a class="link-secondary" href="${field.marketplace_data.category_url}" target="_blank">
                  Buy this on Ever Loved
                  <img src="${props.arrowSecondaryPath}" width="20" height="15" alt="Right arrow">
                </a>
              </div>
            </td>
            <td class="pricing-secondary-cell align-middle">
              <div class="marketplace-average-price">
                ${minPrice} -<br class="d-md-none"> ${maxPrice}
              </div>
            </td>
          </tr>`,
        ),
      );
    }
  }

  function updatePriceListTable(priceListTable, pricingDetails) {
    const pricesToSum = [];
    priceListTable.querySelectorAll("tr").forEach((tableRow) => {
      tableRow.remove();
    });

    if (services.viewing || services.service) {
      insertPriceTableRow(priceListTable, pricingDetails.basic_services);
      insertPriceTableRow(priceListTable, pricingDetails.transfer_of_remains);
      pricesToSum.push(pricingDetails.basic_services.price);
      pricesToSum.push(pricingDetails.transfer_of_remains.price);
    }

    if (services.embalming) {
      insertPriceTableRow(priceListTable, pricingDetails.embalming);
      pricesToSum.push(pricingDetails.embalming.price);
    }

    if (services.viewing) {
      insertPriceTableRow(priceListTable, pricingDetails.viewing_visitation);
      pricesToSum.push(pricingDetails.viewing_visitation.price);
    }

    if (services.service) {
      insertPriceTableRow(priceListTable, pricingDetails.memorial_service);
      pricesToSum.push(pricingDetails.memorial_service.price);
    }

    if (services.burial && services.service) {
      insertPriceTableRow(priceListTable, pricingDetails.graveside_service);
      pricesToSum.push(pricingDetails.graveside_service.price);
    }

    if (services.burial && (services.service || services.viewing)) {
      insertPriceTableRow(priceListTable, pricingDetails.funeral_coach);
      pricesToSum.push(pricingDetails.funeral_coach.price);
    }

    if (
      (services.cremation || services.body_composting || services.liquid_cremation) &&
      (services.service || services.viewing || services.embalming)
    ) {
      if (services.cremation) {
        insertPriceTableRow(priceListTable, pricingDetails.cremation);
        pricesToSum.push(pricingDetails.cremation.price);
      } else if (services.body_composting) {
        insertPriceTableRow(priceListTable, pricingDetails.body_composting);
        pricesToSum.push(pricingDetails.body_composting.price);
      } else if (services.liquid_cremation) {
        insertPriceTableRow(priceListTable, pricingDetails.liquid_cremation);
        pricesToSum.push(pricingDetails.liquid_cremation.price);
      }
    }

    if (
      (services.cremation || services.body_composting || services.liquid_cremation) &&
      !services.service &&
      !services.viewing &&
      !services.embalming
    ) {
      if (services.cremation) {
        insertPriceTableRow(priceListTable, pricingDetails.direct_cremation);
        pricesToSum.push(pricingDetails.direct_cremation.price);
      } else if (services.body_composting) {
        insertPriceTableRow(priceListTable, pricingDetails.body_composting);
        pricesToSum.push(pricingDetails.body_composting.price);
      } else if (services.liquid_cremation) {
        insertPriceTableRow(priceListTable, pricingDetails.liquid_cremation);
        pricesToSum.push(pricingDetails.liquid_cremation.price);
      }
    }

    if (
      services.burial &&
      !services.service &&
      !services.viewing &&
      !services.embalming
    ) {
      insertPriceTableRow(priceListTable, pricingDetails.direct_burial);
      pricesToSum.push(pricingDetails.direct_burial.price);
    }

    if (services.embalming) {
      insertPriceTableRow(priceListTable, pricingDetails.body_prep_embalming);
      pricesToSum.push(pricingDetails.body_prep_embalming.price);
    }

    if (
      services.burial &&
      !services.embalming &&
      (services.viewing || services.service)
    ) {
      insertPriceTableRow(priceListTable, pricingDetails.body_prep);
      pricesToSum.push(pricingDetails.body_prep.price);
    }

    if (
      (services.cremation || services.body_composting || services.liquid_cremation) &&
      services.viewing
    ) {
      insertPriceTableRow(
        priceListTable,
        pricingDetails.casket_rental,
        pricingDetails.casket_rental_max,
      );
      pricesToSum.push(pricingDetails.casket_rental.price);
    }

    if (services.cremation) {
      insertPriceTableRow(priceListTable, pricingDetails.urn, pricingDetails.urn_max);
      pricesToSum.push(pricingDetails.urn.price);
    }

    if (services.burial) {
      insertPriceTableRow(
        priceListTable,
        pricingDetails.casket,
        pricingDetails.casket_max,
      );
      insertPriceTableRow(
        priceListTable,
        pricingDetails.burial_vault,
        pricingDetails.burial_vault_max,
      );
      pricesToSum.push(pricingDetails.casket.price);
      pricesToSum.push(pricingDetails.burial_vault.price);
    }

    if (services.cremation) {
      insertPriceTableRow(
        priceListTable,
        pricingDetails.cremation_casket,
        pricingDetails.cremation_casket_max,
      );
      pricesToSum.push(pricingDetails.cremation_casket.price);
    }

    if (services.viewing || services.service) {
      insertPriceTableRow(
        priceListTable,
        pricingDetails.flowers,
        pricingDetails.flowers_max,
      );
      insertPriceTableRow(
        priceListTable,
        pricingDetails.printed_programs,
        pricingDetails.printed_programs_max,
      );
      pricesToSum.push(pricingDetails.flowers.price);
      pricesToSum.push(pricingDetails.printed_programs.price);
    }

    const total = pricesToSum.reduce((a, b) => a + b, 0);
    let firstCell = "<td>Total estimated cost";
    if (props.pricesEdited) {
      firstCell += " (minimum)";
    }

    if (props.canReceivePricingUpdateRequests) {
      firstCell += `<br>
                    <a class="fw-normal" href="${props.requestPricingUpdatePath}">
                       Request exact pricing<img class="d-none d-md-inline ms-2" src="${props.arrowPrimaryPath}" width="20" height="15" alt="Right arrow"><span class="d-md-none">.</span>
                     </a>`;
    }

    firstCell += "</td>";

    priceListTable.querySelector("tbody").append(
      ...generateElements(
        `<tr class="pricing-total-row">
          ${firstCell}
          <td class="pricing-secondary-cell">$${new window.Intl.NumberFormat("US").format(total)}</td>
        </tr>`,
      ),
    );

    priceListTable
      .querySelectorAll("tr:not(.pricing-total-row):not(.marketplace-pricing-row)")
      .forEach((tableRow) => {
        tableRow.addEventListener("click", toggleDescription);
      });

    addTooltips(priceListTable);
  }

  function addTooltips(priceListTable) {
    priceListTable.querySelectorAll(".price-indicator").forEach((indicator) => {
      const secondaryCell = indicator.closest(".pricing-secondary-cell");
      secondaryCell.dataset.bsToggle = "tooltip";
      if (indicator.classList.contains("price-indicator-estimate")) {
        secondaryCell.dataset.bsTitle = ESTIMATED_PRICE_TOOLTIP_COPY;
      } else {
        secondaryCell.dataset.bsTitle = USER_CONTRIBUTED_PRICE_TOOLTIP_COPY;
      }
    });

    initTooltips();
  }

  props.priceListTable
    .querySelectorAll("tr:not(.pricing-total-row):not(.marketplace-pricing-row)")
    .forEach((tableRow) => {
      tableRow.addEventListener("click", toggleDescription);
    });

  tableFilters.querySelectorAll("select").forEach((tableFilter) => {
    tableFilter.addEventListener("change", () => {
      const tableFilterClass = tableFilter.getAttribute("class");
      const selectedValue = tableFilter.value;

      // get the type of filter they have selected from the class name
      // which is defined 11 characters from the start of the class
      const selectionTracking = tableFilterClass.substr(
        tableFilterClass.indexOf("price-list-") + 11,
      );

      updateServices(tableFilterClass, selectedValue);
      updateEstimatedPricesStatement(props.priceListTable);
      updatePriceListTable(props.priceListTable, props.pricingDetails);
      updateLegend(props.pricingDetails);

      trackCustomUserAction(
        window.location.href,
        "funeral home page ctas",
        `pricing-filter-${selectionTracking}`,
      );
    });
  });

  addTooltips(props.priceListTable);
}
