export default function initEditBusinessBase() {
  const form = document.querySelector(".business-edit-container form");

  document.querySelectorAll(".nav-submit").forEach((navSubmit) => {
    navSubmit.addEventListener("click", () => {
      if (form) {
        form.submit();
      } else {
        window.location.href = "/";
      }
    });
  });
}
