import initBusinessAutocomplete from "../components/BusinessAutocomplete";

export default function initCustomerLeadFeedback(props) {
  initBusinessAutocomplete(props.cemeterySearch);
  initBusinessAutocomplete(props.funeralHomeSearch);

  document.querySelectorAll(".other-radio").forEach((otherRadio) => {
    otherRadio.addEventListener("change", () => {
      const nearbyOtherSection = otherRadio
        .closest(".business-selection-container")
        .querySelector(".other-section");
      if (nearbyOtherSection) {
        nearbyOtherSection.classList.toggle("d-none", !otherRadio.checked);
      }
    });
  });
}
