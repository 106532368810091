import Collapse from "bootstrap/js/dist/collapse";
import ExitPopup from "../components/ExitPopup";
import initGoogleAutocomplete from "../components/GoogleAutocomplete";

export default function initCustomerGuidance(props) {
  const exitPopupRoot = document.querySelector(".business-user-guidance-container");
  if (exitPopupRoot && !props.skipBusinessGuidance) {
    window.exitPopup = new ExitPopup(exitPopupRoot, true);

    document.addEventListener("htmx:afterSettle", (event) => {
      if (event.target.classList.contains("business-user-guidance")) {
        event.target.querySelectorAll(".choice-button").forEach((choiceContainer) => {
          const checkbox = choiceContainer.querySelector('input[type="checkbox"]');
          if (checkbox) {
            checkbox.addEventListener("change", () => {
              choiceContainer.classList.toggle("selected", checkbox.checked);
            });
          }
        });

        if (event.target.querySelector(".g-recaptcha-form")) {
          window.initReCAPTCHAs();
        }
      }

      const other = event.target.querySelector(
        'input[type="checkbox"][value="other"],input[type="radio"][value="other"]',
      );
      const otherStrContainer = event.target.querySelector(".interested-other");

      if (other && otherStrContainer) {
        const name = other.getAttribute("name");
        other.parentNode.parentElement
          .querySelectorAll(`[name="${name}"]`)
          .forEach((checkOrRadio) => {
            checkOrRadio.addEventListener("click", () => {
              if (other.checked) {
                otherStrContainer.classList.remove("d-none");
              } else {
                otherStrContainer.classList.add("d-none");
                otherStrContainer.querySelector('input[type="text"]').value = null;
              }

              if (checkOrRadio.value === "no_events" && checkOrRadio.checked) {
                checkOrRadio.parentNode.parentElement
                  .querySelectorAll(`[name="${name}"]`)
                  .forEach((otherOne) => {
                    if (otherOne !== checkOrRadio) {
                      otherOne.checked = false;
                    }
                  });
              }

              if (checkOrRadio.value !== "no_events" && checkOrRadio.checked) {
                const noEvents =
                  checkOrRadio.parentNode.parentElement.querySelector(
                    '[value="no_events"]',
                  );
                if (noEvents) {
                  noEvents.checked = false;
                }
              }
            });
          });
      }

      event.target.querySelectorAll('[data-dismiss="exit-popup"]').forEach((btn) => {
        btn.addEventListener("click", () => {
          window.exitPopup.hide();
        });
      });

      const atNeedGuidanceContainer = event.target.classList.contains(
        "at-need-guidance",
      )
        ? event.target
        : null;
      if (atNeedGuidanceContainer) {
        const relationshipOther = atNeedGuidanceContainer.querySelector(
          'option[value="other"]',
        );
        const relationshipOtherStrContainer =
          atNeedGuidanceContainer.querySelector(".relationship-other");

        if (relationshipOther && relationshipOtherStrContainer) {
          const relationshipSelector = relationshipOther.closest("select");
          relationshipSelector.addEventListener("change", () => {
            relationshipOtherStrContainer.classList.toggle(
              "d-none",
              !Array.from(relationshipSelector.selectedOptions).includes(
                relationshipOther,
              ),
            );
          });
        }
      }

      const citySearchContainer = event.target.querySelector(".city-search-container");
      if (citySearchContainer) {
        const searchBox = citySearchContainer.querySelector('input[type="text"]');

        initGoogleAutocomplete({
          element: searchBox,
          options: {
            types: ["(cities)"],
          },
        });
      }

      const phoneNumberCheckbox = event.target.querySelector(
        '.phone-call-preferred-radios input[type="radio"][value="True"]',
      );
      const phoneNumberContainer = event.target.querySelector(
        ".phone-number-container",
      );
      if (phoneNumberCheckbox && phoneNumberContainer) {
        const updatePhoneNumberCollapse = () => {
          const collapse = Collapse.getOrCreateInstance(phoneNumberContainer, {
            toggle: false,
          });
          if (
            phoneNumberCheckbox.checked ||
            phoneNumberContainer.querySelector("input").value
          ) {
            collapse.show();
          } else {
            collapse.hide();
          }
        };

        event.target
          .querySelectorAll('.phone-call-preferred-radios input[type="radio"]')
          .forEach((radio) =>
            radio.addEventListener("change", updatePhoneNumberCollapse),
          );
        updatePhoneNumberCollapse();
      }
    });

    document.body.addEventListener("dataLayerEvents", (event) => {
      const events = event.detail.value;
      events.forEach((evt) => {
        window.dataLayer.push(evt);
      });
    });

    document.body.addEventListener("closeUserGuidance", () => {
      window.exitPopup.hide();
    });

    document.body.addEventListener("showContactInfo", () => {
      window.exitPopup.hide();

      const contactInfo = document.querySelector("#about-selected-header");
      if (contactInfo) {
        contactInfo.scrollIntoView(false);
      }
    });

    document.querySelectorAll(".show-business-user-guidance").forEach((el) => {
      el.addEventListener("click", () => {
        const hasHxTarget = el.getAttribute("hx-target") === ".business-user-guidance";
        window.exitPopup.show(true, !hasHxTarget);
      });
    });
  }

  document.body.addEventListener("openPartnerUrl", (event) => {
    window.exitPopup.hide();
    window.open(event.detail.value, "_blank");
  });
}
