import initExpander from "../components/Expander";
import initSuccessModal from "../components/SuccessModal";
import initReCAPTCHAs from "../core/reCAPTCHA";
import { trackCustomUserAction } from "../core/utils";
import { initBusinessPromosDrawer } from "./utils";

export default function initSearchPage(props) {
  if (window.isOnMobile) {
    initExpander(document.querySelector(".page-subheader-mobile"));
  }

  initSuccessModal();

  document.querySelectorAll(".business-link").forEach((businessLink) => {
    businessLink.addEventListener("click", () => {
      const businessCard = businessLink.closest(".business-card");
      const isFirstCard = businessCard === businessCard.parentElement.firstElementChild;

      let action = `visit-${props.businessType}-not-1st-result`;
      if (props.isFirstPage && isFirstCard) {
        action = `visit-${props.businessType}-1st-result`;
      }

      trackCustomUserAction(
        window.location.href,
        props.googleAnalyticsCategory,
        action,
      );
    });
  });

  initBusinessPromosDrawer();

  window.initReCAPTCHAs = initReCAPTCHAs;
}
