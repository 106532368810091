import { animateCSS, sendBrowserAgnosticEvent, sleep } from "../core/utils";

/**
 * Initialize behavior for business questions.
 *
 * @param {object} props - the page props
 */
export default function initBusinessQuestionModule(props) {
  const questionSuccessModal = document.querySelector(".send-question-success-modal");

  /**
   * Show the success modal briefly, then dismiss it automatically.
   */
  const showSuccessModal = async () => {
    questionSuccessModal.classList.remove("d-none");
    animateCSS(questionSuccessModal, "fadeIn");
    await sleep(5000);
    await animateCSS(questionSuccessModal, "fadeOut");
    questionSuccessModal.classList.add("d-none");
  };

  if (props.questionSent) {
    showSuccessModal();
  }

  const answerForms = document.querySelectorAll(".business-question-answer-form");
  answerForms.forEach((answerForm) => {
    answerForm.addEventListener("submit", (event) => {
      const answerField = answerForm.querySelector("textarea");
      const errorMessage = answerForm.querySelector(".error");
      if (!answerField || !answerField.value) {
        sendBrowserAgnosticEvent(answerForm, "cancel-submit-protect");
        event.preventDefault();

        if (errorMessage) {
          errorMessage.classList.remove("d-none");
        }
      }
    });
  });

  const editAnswerLinks = document.querySelectorAll(".edit-answer-link");
  editAnswerLinks.forEach((link) => {
    link.addEventListener("click", (event) => {
      const answerContainer = event.target.closest(".answer");
      const answerTextContainer = answerContainer.querySelector(".text");
      const answerFormContainer = answerContainer.querySelector(".form");

      event.target.classList.add("d-none");
      answerTextContainer.classList.add("d-none");
      answerFormContainer.classList.remove("d-none");
    });
  });
}
