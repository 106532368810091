import initCommunityOrganizationAutocomplete from "../components/CommunityOrgAutocomplete";
import initOtherField from "../components/OtherField";
import { scrollInMemorialPrivateContentContainer } from "../memorials/utils";
import initGoogleAutocomplete from "../components/GoogleAutocomplete";
import MasonFlexContainer from "../components/MasonFlexContainer";
import { sendBrowserAgnosticEvent } from "../core/utils";

/**
 * Initialize the private Community Involvement editing page.
 *
 * @param {object} props - the page props
 */
export default function initMemorialPrivateCommunityInvolvement(props) {
  initCommunityOrganizationAutocomplete(props.organizationSearch);
  const orgSearch = document.getElementById(props.organizationSearch.identifierInputId);

  // Some state.
  let organizationTypeSelected = false;

  // Get most of the things we need to work.
  const affiliationsContainers = document.querySelectorAll(".affiliations");
  const orgSearchContainer = document.querySelector(".community-organization-search");
  const cancelButtons = document.querySelectorAll(".affiliations .cancel");
  const orgTypeRadios = document.querySelectorAll(
    'input[name="affiliation-organization_type"]',
  );
  const orgNameInputGroup = document.querySelector(".organization-name-input-group");
  const affiliationTypeInputGroup = document.querySelector(
    ".affiliation-type-input-group",
  );
  const createOrganizationContainer = document.querySelector(".create-organization");
  const religionFieldsContainer =
    createOrganizationContainer.querySelector(".religion-fields");
  const orgTypeOtherRadio = document.querySelector(
    `input[name="affiliation-organization_type"][value="${props.organizationTypes.OTHER}"]`,
  );
  const submitRow = document.querySelector(".form-submit-row");
  const skipLink = document.querySelector(".skip-link");
  const addContainer = document.querySelector(".add-affiliation-container");
  const affiliationOrganizationSlug = document.querySelector(
    `#${props.affiliationForm.fields.organizationSlug}`,
  );
  const affiliationAddingNewOrg = document.querySelector(
    `#${props.affiliationForm.fields.affiliationAddingNewOrg}`,
  );
  const organizationName = document.querySelector(
    `#${props.organizationForm.fields.name}`,
  );
  const organizationType = document.querySelector(
    `#${props.organizationForm.fields.organizationType}`,
  );
  const organizationAddingNewOrg = document.querySelector(
    `#${props.organizationForm.fields.organizationAddingNewOrg}`,
  );

  // Set up "other" str fields.
  const affiliationTypeOtherRadio = document.querySelector(
    `input[name="affiliation-affiliation_type"][value="${props.affiliationTypes.OTHER}"]`,
  );
  const affiliationTypeStr = document.querySelector(
    `#${props.affiliationForm.fields.affiliationTypeStr}`,
  );
  const religionTypeOtherRadio = document.querySelector(
    `input[name="organization-religion"][value="${props.religions.OTHER}"]`,
  );
  const religionTypeStr = document.querySelector(
    `#${props.organizationForm.fields.religionStr}`,
  );
  initOtherField(affiliationTypeOtherRadio, affiliationTypeStr, null);
  initOtherField(religionTypeOtherRadio, religionTypeStr, null);

  // There are three affiliation categories, only one will be active at a time while
  // editing. Get that container.
  const getCurrentAffiliationContainer = () => {
    const visibleAffiliationsContainers = Array.from(affiliationsContainers).filter(
      (container) => !container.classList.contains("d-none"),
    );
    if (visibleAffiliationsContainers.length !== 1) {
      return null;
    }
    return visibleAffiliationsContainers[0];
  };

  // Update the placeholder in the autocomplete search
  const updateSearchPlaceholder = (placeholder) => {
    orgSearch.tomselect.settings.placeholder = placeholder;
    orgSearch.tomselect.inputState();
  };

  // Update the available organizations based on selected affiliation category and
  // any changes in which org type is checked.
  const updateOrganizationEntry = (newlyCheckedOrgTypeRadio = null) => {
    const currentAffiliationContainer = getCurrentAffiliationContainer();
    if (!currentAffiliationContainer) {
      return;
    }

    const religiousOrgTypeRadios = Object.keys(props.religionsByOrganizationType).map(
      (orgType) =>
        document.querySelector(
          `input[name="affiliation-organization_type"][value="${orgType}"]`,
        ),
    );
    const socialOrgTypeRadios = props.socialOrgTypes.map((orgType) =>
      document.querySelector(
        `input[name="affiliation-organization_type"][value="${orgType}"]`,
      ),
    );
    const volunteerOrgTypeRadios = props.volunteerOrgTypes.map((orgType) =>
      document.querySelector(
        `input[name="affiliation-organization_type"][value="${orgType}"]`,
      ),
    );

    const isReligious = currentAffiliationContainer.classList.contains("religious");
    const isClub = currentAffiliationContainer.classList.contains("club");
    const isVolunteer = currentAffiliationContainer.classList.contains("volunteer");

    religiousOrgTypeRadios.forEach((radio) => {
      radio.checked = radio === newlyCheckedOrgTypeRadio;
      radio.closest(".el-radio-btn").classList.toggle("d-none", !isReligious);

      if (radio.checked) {
        const relevantReligions = props.religionsByOrganizationType[radio.value].concat(
          [props.religions.OTHER],
        );
        religionFieldsContainer
          .querySelectorAll(".el-radio-btn")
          .forEach((religionRadioContainer) => {
            const religionRadio =
              religionRadioContainer.querySelector('input[type="radio"]');
            const isRelevant = relevantReligions.some(
              (religion) => `${religion}` === religionRadio.value,
            );
            religionRadioContainer.classList.toggle("d-none", !isRelevant);
          });

        updateSearchPlaceholder(props.organizationPlaceholders.religious);
      }
    });

    socialOrgTypeRadios.forEach((radio) => {
      radio.checked = radio === newlyCheckedOrgTypeRadio;
      radio.closest(".el-radio-btn").classList.toggle("d-none", !isClub);

      if (radio.checked) {
        updateSearchPlaceholder(props.organizationPlaceholders.club);
      }
    });

    volunteerOrgTypeRadios.forEach((radio) => {
      radio.checked = radio === newlyCheckedOrgTypeRadio;
      radio.closest(".el-radio-btn").classList.toggle("d-none", !isVolunteer);

      if (radio.checked) {
        updateSearchPlaceholder(props.organizationPlaceholders.volunteer);
      }
    });

    orgTypeOtherRadio.checked = orgTypeOtherRadio === newlyCheckedOrgTypeRadio;
    orgTypeOtherRadio.closest(".el-radio-btn").classList.toggle("d-none", isReligious);

    orgTypeRadios.forEach((radio) => {
      if (newlyCheckedOrgTypeRadio) {
        radio
          .closest(".el-radio-btn")
          .classList.toggle("d-none", newlyCheckedOrgTypeRadio !== radio);
      }

      document
        .querySelector(".clear-radios")
        .classList.toggle("d-none", !newlyCheckedOrgTypeRadio);
    });

    orgNameInputGroup.classList.toggle("d-none", !newlyCheckedOrgTypeRadio);
  };

  // Handle canceling adding an affiliation.
  cancelButtons.forEach((btn) => {
    btn.addEventListener("click", () => {
      submitRow.classList.add("d-none");
      if (skipLink) {
        skipLink.classList.remove("d-none");
      }
      affiliationTypeInputGroup.classList.add("d-none");
      affiliationTypeInputGroup.classList.remove("invisible");
      createOrganizationContainer.classList.add("d-none");
      religionFieldsContainer.classList.add("d-none");
      orgSearchContainer.classList.add("d-none");
      cancelButtons.forEach((innerBtn) => {
        innerBtn.classList.add("d-none");
      });
      affiliationsContainers.forEach((container) => {
        container.classList.remove("d-none");
        container.classList.remove("selected");
        const addButton = container.querySelector(".add-affiliation");
        if (addButton) {
          addButton.classList.remove("d-none");
        }
        container.querySelectorAll(".affiliation").forEach((aff) => {
          aff.classList.remove("d-none");
        });
      });
    });
  });

  // Handle clearing org type radios.
  document.querySelector(".clear-radios").addEventListener("click", () => {
    orgTypeRadios.forEach((btn) => {
      btn.checked = false;
    });
    orgSearch.tomselect.setValue(null);
    organizationTypeSelected = false;
    updateOrganizationEntry();
  });

  /**
   * Handle start adding affiliation for the different affiliation categories.
   *
   * @param {Event} evt - the event triggering adding an affiliation.
   */
  const startAddAffiliation = (evt) => {
    affiliationsContainers.forEach((container) => {
      if (!container.contains(evt.currentTarget)) {
        container.classList.add("d-none");
      } else {
        container.classList.add("selected");
        container.querySelector(".cancel").classList.remove("d-none");
        container.querySelectorAll(".affiliation").forEach((aff) => {
          aff.classList.add("d-none");
        });
      }
    });

    if (props.isOnboarding) {
      addContainer.scrollIntoView();
    } else {
      scrollInMemorialPrivateContentContainer(addContainer, -200);
    }

    orgSearchContainer.classList.remove("d-none");
    evt.currentTarget.classList.add("d-none");
    submitRow.classList.remove("d-none");
    if (skipLink) {
      skipLink.classList.add("d-none");
    }

    updateOrganizationEntry();
  };

  document.querySelectorAll(".add-affiliation").forEach((btn) => {
    btn.addEventListener("click", startAddAffiliation);
  });

  // Handle selection of org types.
  orgTypeRadios.forEach((radio) => {
    radio.addEventListener("change", () => {
      if (radio.checked) {
        // Set the org name text.
        if (radio.value === `${props.organizationTypes.OTHER}`) {
          document.querySelector(".organization-name").innerText = "Organization name";
        } else {
          const orgTypeName = props.organizationTypes.choices.find(
            (t) => `${t[0]}` === radio.value,
          )[1];
          document.querySelector(".organization-name").innerText =
            `${orgTypeName} name`;
        }
      }

      // Reset value via autocomplete...
      if (organizationTypeSelected) {
        orgSearch.tomselect.setValue(null);
      }

      updateOrganizationEntry(radio);
      organizationTypeSelected = true;
    });
  });

  /**
   * Show affiliation controls after org is selected.
   */
  const showAffiliationControls = () => {
    // If volunteer, disable everything else and "check" the volunteer option.
    // If club, hide volunteer option.
    const currentAffiliationContainer = getCurrentAffiliationContainer();
    const lockToVolunteer =
      currentAffiliationContainer &&
      currentAffiliationContainer.classList.contains("volunteer");
    const hideVolunteer =
      currentAffiliationContainer &&
      currentAffiliationContainer.classList.contains("club");
    const lockToAttendee =
      currentAffiliationContainer &&
      currentAffiliationContainer.classList.contains("religious");

    affiliationTypeInputGroup.classList.toggle(
      "invisible",
      lockToVolunteer || lockToAttendee,
    );
    affiliationTypeInputGroup
      .querySelectorAll(".el-radio-btn")
      .forEach((radioContainer) => {
        const radio = radioContainer.querySelector('input[type="radio"]');
        const isVolunteer = radio.value === "1";
        const isAttendee = radio.value === "4";

        radioContainer.classList.toggle(
          "d-none",
          (lockToVolunteer && !isVolunteer) || (hideVolunteer && isVolunteer),
        );
        if (isVolunteer && lockToVolunteer) {
          radio.checked = true;
        } else if (isAttendee && lockToAttendee) {
          radio.checked = true;
        }
      });

    // Regardless, now show the affiliation type inputs
    affiliationTypeInputGroup.classList.remove("d-none");
  };

  // Handle selection and what form elements need to show.
  orgSearch.addEventListener("change", (event) => {
    const currentAffiliationContainer = getCurrentAffiliationContainer();
    const selectedOption =
      event.target.tomselect.options[event.target.tomselect.getValue()];

    if (selectedOption && selectedOption.created === true) {
      // Show the org creation form
      // Move relevant data from affiliation form to org create form
      createOrganizationContainer.classList.remove("d-none");
      organizationName.value = affiliationOrganizationSlug.value;
      const selectedOrgType = [...orgTypeRadios].find((radio) => radio.checked);
      organizationType.value = selectedOrgType.value;
      affiliationOrganizationSlug.value = "";

      // If religious org is what we're working with, reveal those fields.
      religionFieldsContainer.classList.toggle(
        "d-none",
        !(
          currentAffiliationContainer &&
          currentAffiliationContainer.classList.contains("religious")
        ),
      );
      affiliationAddingNewOrg.value = true;
      organizationAddingNewOrg.value = true;
    } else {
      affiliationAddingNewOrg.value = false;
      organizationAddingNewOrg.value = false;
    }

    showAffiliationControls();
  });

  // Form submit handler to proxy in org info to the org form as necessary.
  const form = document.querySelector("form.community-involvement-form");
  if (form) {
    form.addEventListener("submit", () => {
      const selectedValue = orgSearch.tomselect.getValue();

      if (selectedValue) {
        const orgNameHiddenField = document.querySelector(
          `#${props.organizationForm.fields.name}`,
        );
        const orgTypeHiddenField = document.querySelector(
          `#${props.organizationForm.fields.organizationType}`,
        );

        const selectedOption = orgSearch.tomselect.options[selectedValue];
        if (selectedOption.created === true) {
          orgNameHiddenField.value = selectedOption.text;
          orgSearch.setValue(null);
          orgTypeHiddenField.value = document.querySelector(
            'input[name="affiliation-organization_type"]:checked',
          ).value;
        }
      }
    });
  }

  if (props.errors) {
    if (props.dirtyOrganizationCategory && props.dirtyOrganizationType) {
      const addButton = document.querySelector(
        `.affiliations.${props.dirtyOrganizationCategory} .add-affiliation`,
      );
      startAddAffiliation({ currentTarget: addButton });

      const checkedOtherRadio = document.querySelector(
        `input[name="affiliation-organization_type"][value="${props.dirtyOrganizationType}"]`,
      );
      checkedOtherRadio.checked = true;
      updateOrganizationEntry(checkedOtherRadio);

      if (props.organizationSearch.initial) {
        showAffiliationControls();
      }

      if (props.dirtyCreatingOrganization) {
        const currentAffiliationContainer = getCurrentAffiliationContainer();
        createOrganizationContainer.classList.remove("d-none");
        religionFieldsContainer.classList.toggle(
          "d-none",
          !(
            currentAffiliationContainer &&
            currentAffiliationContainer.classList.contains("religious")
          ),
        );
        showAffiliationControls();
      }
    }
  }

  // Affiliation masonry flow
  document.querySelectorAll(".mason-flex-container").forEach((container) => {
    const mason = new MasonFlexContainer(container);
    mason.flow();
  });

  // Photo memory form
  const photoMemoryFormContainers = document.querySelectorAll(
    ".affiliation-photo-memory-form",
  );
  photoMemoryFormContainers.forEach((photoMemoryFormContainer) => {
    const captionInput = photoMemoryFormContainer.querySelector(".caption-input input");
    if (!captionInput) {
      return;
    }

    captionInput.setAttribute(
      "placeholder",
      `Ex. ${props.deceasedFirstName} with fellow volunteers`,
    );
    const addPhotoButton =
      photoMemoryFormContainer.parentElement.querySelector(".add-photo-memory");
    addPhotoButton.addEventListener("click", () => {
      addPhotoButton.classList.add("d-none");
      addPhotoButton.closest(".affiliations").classList.add("has-photo-form-expanded");
      photoMemoryFormContainer.classList.remove("d-none");
      sendBrowserAgnosticEvent(
        addPhotoButton.closest(".mason-flex-container"),
        "mason:reflow",
      );
    });

    if (
      props.photoErrors &&
      props.photoErrors.toString() === photoMemoryFormContainer.dataset.affiliation
    ) {
      addPhotoButton.classList.add("d-none");
      addPhotoButton.closest(".affiliations").classList.add("has-photo-form-expanded");
      photoMemoryFormContainer.classList.remove("d-none");
      sendBrowserAgnosticEvent(
        addPhotoButton.closest(".mason-flex-container"),
        "mason:reflow",
      );
    }

    const locationInputContainer =
      photoMemoryFormContainer.querySelector(".location-input");
    initGoogleAutocomplete({
      element: locationInputContainer.querySelector('input[type="text"]'),
      metadataElement: locationInputContainer.querySelector('input[type="hidden"]'),
      options: {
        types: ["establishment", "geocode"],
      },
    });
  });
}
