export default function initEditBusinessEmployees(props) {
  if (!props.employeeFormset) {
    return;
  }

  const { emptyForm } = props.employeeFormset;
  const totalFormsField = document.getElementById(
    props.employeeFormset.totalFormsFieldId,
  );
  let totalForms = props.employeeFormset.total;

  const updateSubmitButton = () => {
    const visibleFormCount = document.querySelectorAll(
      "tr.employee-form-row:not(.hidden)",
    ).length;
    const submitButton = document.querySelector("#invite-employees-btn");
    const countSpan = submitButton.querySelector(".count");
    countSpan.innerText = `${visibleFormCount} invitation${visibleFormCount === 1 ? "" : "s"}`;
    if (visibleFormCount === 1) {
      const fields = document.querySelectorAll(
        'tr.employee-form-row:not(.hidden) input[type="email"], tr.employee-form-row:not(.hidden) input[type="text"]',
      );
      submitButton.disabled = !Array.from(fields).every((field) => field.value);
    } else {
      submitButton.disabled = false;
    }

    document
      .querySelectorAll("tr.employee-form-row .remove")
      .forEach((removeButton) => {
        removeButton.closest("td").classList.toggle("d-none", visibleFormCount === 1);
      });
  };

  const updateNumberOfForms = (increment) => {
    totalForms += increment;
    totalFormsField.value = totalForms;

    updateSubmitButton();
  };

  const removeFormHandler = (event) => {
    const formRow = event.currentTarget.closest("tr.employee-form-row");
    const deleteField = formRow.querySelector('input[type="hidden"]');
    deleteField.value = "1";

    formRow.classList.add("hidden");

    updateSubmitButton();
  };

  const initFormInputBehavior = (formRow) => {
    formRow.querySelectorAll("input").forEach((input) => {
      ["input", "change", "paste"].forEach((ev) => {
        input.addEventListener(ev, () => {
          updateSubmitButton();
        });
      });
    });
  };

  document.querySelectorAll("tr.employee-form-row").forEach((formContainer) => {
    formContainer.querySelector(".remove").addEventListener("click", removeFormHandler);
    initFormInputBehavior(formContainer);
  });

  const addEmployeeFormRowButton = document.querySelector(".add-employee-form-row");
  if (addEmployeeFormRowButton) {
    addEmployeeFormRowButton.addEventListener("click", () => {
      const container = document.querySelector(".add-employee-formset-table > tbody");
      const newIndex = totalForms;

      // Create new form.
      const dummyContainer = document.createElement("table");

      // Put in HTML and insert into DOM.
      dummyContainer.innerHTML = emptyForm.replace(/__prefix__/g, newIndex);
      const tableRow = dummyContainer.querySelector("tr");
      container.appendChild(tableRow);
      tableRow.querySelector(".remove").addEventListener("click", removeFormHandler);

      initFormInputBehavior(tableRow);

      // Update count state.
      updateNumberOfForms(1);
    });
  }

  updateNumberOfForms(0);
}
