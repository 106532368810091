import initGoogleAutocomplete from "../components/GoogleAutocomplete";
import initPhotoCropper from "../components/PhotoCropper";

function getAddressComponents(place) {
  let cityName;
  let postalCode;
  let route;
  let stateAbbreviation;
  let streetNumber;
  let subPremise;

  for (let i = 0; i < place.address_components.length; i += 1) {
    const addressType = place.address_components[i].types[0];
    if (addressType === "locality") {
      cityName = place.address_components[i].long_name;
    } else if (addressType === "postal_code") {
      postalCode = place.address_components[i].short_name;
    } else if (addressType === "route") {
      route = place.address_components[i].long_name;
    } else if (addressType === "administrative_area_level_1") {
      stateAbbreviation = place.address_components[i].short_name;
    } else if (addressType === "street_number") {
      streetNumber = place.address_components[i].short_name;
    } else if (addressType === "subpremise") {
      subPremise = place.address_components[i].short_name;
    }
  }

  return {
    cityName,
    postalCode,
    route,
    stateAbbreviation,
    streetNumber,
    subPremise,
  };
}

function updateFormElements(autocomplete, props) {
  // get form elements
  const cityName = document.getElementById(props.cityNameIdStr);
  const googlePlaceID = document.getElementById(props.googlePlaceIdStr);
  const latitude = document.getElementById(props.latitudeIdStr);
  const longitude = document.getElementById(props.longitudeIdStr);
  const postalCode = document.getElementById(props.postalCodeIdStr);
  const stateAbbreviation = document.getElementById(props.stateAbbreviationIdStr);
  const streetAddress = document.getElementById(props.streetAddressIdStr);

  const place = autocomplete.getPlace();
  const location = place.geometry.location.toJSON();
  const addressComponents = getAddressComponents(place);

  // assign values to form elements
  if (googlePlaceID) {
    googlePlaceID.value = place.place_id;
    latitude.value = location.lat;
    longitude.value = location.lng;
  }

  if (addressComponents.cityName) {
    cityName.value = addressComponents.cityName;
  } else {
    cityName.value = null;
  }

  if (addressComponents.postalCode) {
    postalCode.value = addressComponents.postalCode;
  } else {
    postalCode.value = null;
  }

  if (addressComponents.stateAbbreviation) {
    stateAbbreviation.value = addressComponents.stateAbbreviation;
  } else {
    stateAbbreviation.value = null;
  }

  if (addressComponents.streetNumber && addressComponents.route) {
    if (addressComponents.subPremise) {
      streetAddress.value = `${addressComponents.streetNumber} ${addressComponents.route} #${addressComponents.subPremise}`;
    } else {
      streetAddress.value = `${addressComponents.streetNumber} ${addressComponents.route}`;
    }
  } else {
    streetAddress.value = null;
  }
}

export default async function initEditBusinessBasics(props) {
  const photoInput = document.querySelector(`#${props.photoInputId}`);
  if (photoInput && !props.skipCropper) {
    initPhotoCropper({
      photoInput,
      xInput: document.querySelector(`#${props.xInputId}`),
      yInput: document.querySelector(`#${props.yInputId}`),
      heightInput: document.querySelector(`#${props.heightInputId}`),
      widthInput: document.querySelector(`#${props.widthInputId}`),
      imgPlaceholder: props.imgPlaceholder,
    });
  }

  const autocomplete = await initGoogleAutocomplete({
    element: document.getElementById(props.autocompleteElementIdStr),
    options: {
      types: ["establishment", "geocode"],
    },
  });

  if (autocomplete) {
    window.google.maps.event.addListener(autocomplete, "place_changed", () => {
      updateFormElements(autocomplete, props);
    });
  }
}
