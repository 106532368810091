import { scrollToElement } from "../core/utils";
import requests, { ResponseError } from "../core/requests";

/**
 * Initialize the funeral home search filters behavior, which includes a fair amount
 * of duplication due to mobile vs. desktop display.
 *
 * @param {object} props - the page props
 */
export default function initFuneralHomeFilters(props) {
  const andUps = document.querySelectorAll(".business-filters-mobile-overlay .and-up");
  const body = document.querySelector("body");
  const filterCount = document.querySelector(".filter-count");
  const filterSubmits = document.querySelector(".filter-submits");
  const filterToggle = document.querySelector(".business-filters-mobile");
  const noResultsCard = document.querySelector(".no-results-card-mobile");
  const overlay = document.querySelector(".business-filters-mobile-overlay");
  const ratingStars = document.querySelectorAll(
    ".business-filters-mobile-overlay .star-rating div",
  );
  const ratingStarsContainers = document.querySelectorAll(
    ".business-filters-mobile-overlay .star-rating-container",
  );
  let searchParams = new URLSearchParams(window.location.search);
  const tierBtns = document.querySelectorAll(
    ".business-filters-mobile-overlay .pill-btn div",
  );

  const filters = document.querySelectorAll("*[data-update-filter]");
  filters.forEach((filter) => {
    filter.addEventListener("click", () => {
      window.location.href = filter.getAttribute("data-update-filter");
    });
  });

  /**
   * Display the proper empty card UX if there are no matching Funeral Homes
   *
   * @param {number} count - the number of matching funeral homes
   */
  const showEmptyCardIfNeeded = (count) => {
    if (count === 0) {
      filterSubmits.classList.add("d-none");
      noResultsCard.classList.remove("d-none");
    } else {
      filterSubmits.classList.remove("d-none");
      noResultsCard.classList.add("d-none");
    }
  };

  /**
   * Given the user-selected filters, get the number of matching funeral homes and
   * then update display based on that.
   *
   * @returns {Promise<void>} an ignorable void-promise
   */
  async function updateCount() {
    let responseData;

    try {
      const response = await requests.get(props.countEndpoint, searchParams);

      if (!response.ok) {
        throw new ResponseError(response);
      }

      responseData = await response.json();
    } catch (error) {
      window.Rollbar.warn("Error updating funeral home matching filter count", error);
    }

    if (responseData) {
      filterCount.innerText = responseData.count;
      showEmptyCardIfNeeded(responseData.count);
    }
  }

  tierBtns.forEach((tierBtn) => {
    tierBtn.addEventListener("touchstart", () => {
      const tier = tierBtn.dataset.priceTier;
      const currentPriceTiers = searchParams.getAll("price_tier");

      // if the tier is already selected, remove it from query params if needed
      if (tierBtn.classList.contains("selected")) {
        tierBtn.classList.remove("selected");
        const tierIndex = currentPriceTiers.indexOf(tier);
        if (tierIndex > -1) {
          searchParams.delete("price_tier");
          currentPriceTiers.splice(tierIndex, 1);
          currentPriceTiers.forEach((tierToAdd) => {
            searchParams.append("price_tier", tierToAdd);
          });
        }
        // if the tier is not yet selected, add the new tier if needed
      } else {
        tierBtn.classList.add("selected");
        if (currentPriceTiers.indexOf(tier) === -1) {
          searchParams.append("price_tier", tier);
        }
      }

      updateCount();
    });
  });

  ratingStars.forEach((ratingBtn) =>
    ratingBtn.addEventListener("touchstart", () => {
      const { rating } = ratingBtn.dataset;
      const currentRating = searchParams.get("rating");

      if (rating === currentRating) {
        ratingStars.forEach((star) => star.classList.remove("selected"));
        ratingStarsContainers.forEach((container) =>
          container.classList.remove("selected"),
        );
        andUps.forEach((andUp) => andUp.classList.remove("d-none"));

        searchParams.delete("rating", rating);
      } else {
        ratingStars.forEach((star) => star.classList.remove("selected"));

        let encounteredSelf = false;
        ratingBtn.parentElement.querySelectorAll("div").forEach((star) => {
          if (encounteredSelf || star === ratingBtn) {
            encounteredSelf = true;
            star.classList.add("selected");
          }
        });

        ratingStarsContainers.forEach((container) =>
          container.classList.add("selected"),
        );

        andUps.forEach((andUp) => andUp.classList.toggle("d-none", rating === 5));

        searchParams.set("rating", rating);
      }

      updateCount();
    }),
  );

  document.querySelectorAll(".close-overlay").forEach((closer) =>
    closer.addEventListener("click", () => {
      overlay.classList.toggle("open");
      body.classList.toggle("no-scroll");
      scrollToElement(filterToggle.closest(".body-card"));

      if (window.Beacon) {
        window.Beacon("config", { hideFABOnMobile: false });
      }
    }),
  );

  document.querySelectorAll(".filters-overlay-toggle").forEach((toggler) =>
    toggler.addEventListener("click", () => {
      overlay.classList.toggle("open");
      body.classList.toggle("no-scroll");
      scrollToElement(filterToggle.closest(".body-card"));

      if (window.Beacon) {
        window.Beacon("config", { hideFABOnMobile: true });
      }
    }),
  );

  document.querySelectorAll(".clear-filters").forEach((clearer) =>
    clearer.addEventListener("click", () => {
      tierBtns.forEach((btn) => btn.classList.remove("selected"));
      ratingStarsContainers.forEach((ratingStarsContainer) =>
        ratingStarsContainer.classList.remove("selected"),
      );
      ratingStars.forEach((star) => star.classList.remove("selected"));
      andUps.forEach((andUp) => andUp.classList.remove("d-none"));
      searchParams = new URLSearchParams("");
      updateCount();
    }),
  );

  document.querySelector(".apply-filters").addEventListener("click", () => {
    window.location.hash = "#filter-controls";
    window.location.search = searchParams.toString();
  });
}
