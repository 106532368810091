import initCommunityFAQs from "../../components/CommunityFAQs";
import initCustomerGuidance from "../customerGuidance";
import initFuneralHomePriceList from "../../components/FuneralHomePriceList";

export default function initFuneralHome(props) {
  initFuneralHomePriceList({
    arrowPrimaryPath: props.arrowPrimaryPath,
    arrowSecondaryPath: props.arrowSecondaryPath,
    blueCirclePath: props.blueCirclePath,
    blueRightArrowPath: props.blueRightArrowPath,
    canReceivePricingUpdateRequests: props.canReceivePricingUpdateRequests,
    caretPath: props.caretPath,
    funeralProductPricingLinks: props.funeralProductPricingLinks,
    grayCaretPath: props.grayCaretPath,
    originalServices: props.originalServices,
    priceListTable: document.querySelector(".price-list-table"),
    pricesEdited: props.pricesEdited,
    pricingDetails: props.pricingDetails,
    requestPricingUpdatePath: props.requestPricingUpdatePath,
    yellowCirclePath: props.yellowCirclePath,
  });

  initCommunityFAQs({ skipUserPostInit: true });
  initCustomerGuidance(props);
}
