function updateChildPricingDisplay(addChildPricingToggle) {
  const cemeteryPriceForm = addChildPricingToggle.closest(".cemetery-price-form");
  const serviceNames = cemeteryPriceForm.querySelectorAll(".row-detail span");

  if (addChildPricingToggle.checked) {
    cemeteryPriceForm.querySelector(".child-pricing").classList.remove("d-none");
    serviceNames[0].classList.add("d-none");
    serviceNames[1].classList.remove("d-none");
  } else {
    cemeteryPriceForm.querySelector(".child-pricing").classList.add("d-none");
    serviceNames[0].classList.remove("d-none");
    serviceNames[1].classList.add("d-none");
  }
}

export default function initEditCemeteryPrices() {
  document.querySelectorAll(".clear-pricing-toggle").forEach((clearPricingToggle) => {
    clearPricingToggle.addEventListener("click", () => {
      const cemeteryPriceForm = clearPricingToggle.closest(".cemetery-price-form");
      cemeteryPriceForm.querySelectorAll(".cell-price").forEach((cellPrice) => {
        const cellPriceInput = cellPrice.querySelector("input");
        if (clearPricingToggle.checked) {
          cellPriceInput.removeAttribute("disabled");
          cellPriceInput.setAttribute("placeholder", "Enter Price");
          cellPriceInput.classList.toggle("price-input-disabled");
        } else {
          cellPriceInput.setAttribute("disabled", "disabled");
          cellPriceInput.value = "";
          cellPriceInput.setAttribute("placeholder", "N/A");
          cellPriceInput.classList.toggle("price-input-disabled");

          const addChildPricingToggle = cemeteryPriceForm.querySelector(
            ".add-child-pricing-toggle",
          );
          addChildPricingToggle.checked = false;
          updateChildPricingDisplay(addChildPricingToggle);
        }
      });
    });
  });

  document
    .querySelectorAll(".add-child-pricing-toggle")
    .forEach((addChildPricingToggle) => {
      addChildPricingToggle.addEventListener("click", () => {
        updateChildPricingDisplay(addChildPricingToggle);
      });
    });
}
