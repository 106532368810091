export default function initEditFuneralHomePrices() {
  document.querySelectorAll(".price-checkbox").forEach((priceCheckbox) => {
    priceCheckbox.addEventListener("click", () => {
      // Find the label and the input and toggle the states when you uncheck
      // the box in the pricing table
      const nearestLabel = priceCheckbox.parentElement.querySelector("label");
      nearestLabel.classList.toggle("label-gray");

      // The logic for finding the range input fields in the edit pricing table
      // is a bit different since the containers are a bit different
      const nearestRangeInput = priceCheckbox
        .closest(".row-wrapper")
        .querySelectorAll(":scope > .edit-price-range-wrapper .row-price");
      if (nearestRangeInput.length) {
        nearestRangeInput.forEach((element) => {
          const fieldInput = element.querySelector("input");
          if (fieldInput.getAttribute("placeholder") === "N/A") {
            fieldInput.removeAttribute("disabled");
            fieldInput.setAttribute("placeholder", "Enter Price");
            fieldInput.classList.toggle("price-input-disabled");
            fieldInput.focus();
          } else {
            fieldInput.setAttribute("disabled", "disabled");
            fieldInput.value = "";
            fieldInput.setAttribute("placeholder", "N/A");
            fieldInput.classList.toggle("price-input-disabled");
          }
        });
      }

      const nearestPriceInput = priceCheckbox
        .closest(".row-wrapper")
        .querySelector(":scope > .row-price input");
      if (nearestPriceInput) {
        if (nearestPriceInput.getAttribute("placeholder") === "N/A") {
          nearestPriceInput.removeAttribute("disabled");
          nearestPriceInput.setAttribute("placeholder", "Enter Price");
          nearestPriceInput.classList.toggle("price-input-disabled");
          nearestPriceInput.focus();
        } else {
          nearestPriceInput.setAttribute("disabled", "disabled");
          nearestPriceInput.value = "";
          nearestPriceInput.setAttribute("placeholder", "N/A");
          nearestPriceInput.classList.toggle("price-input-disabled");
        }
      }
    });
  });

  document.querySelectorAll(".price-field-error").forEach((priceFieldError) => {
    priceFieldError.closest(".row-wrapper").style.backgroundColor = "#F8D7DA";
  });
}
