/**
 * Initialize a dynamic experience for a selector field and an "other" freeform entry.
 *
 * @param {HTMLSelectElement|HTMLInputElement} selectorField - a <select> or the specifically
 *     "other" <input type="radio">
 * @param {HTMLInputElement|HTMLTextAreaElement} otherField - a <input type="text"> or
 *     <textarea> field
 * @param {string} [otherValue] - if selectorField is a <select>, the "value" of
 *     the "other" option.
 */
export default function initOtherField(selectorField, otherField, otherValue = null) {
  const otherContainer = otherField.parentNode;

  const toggleOtherField = (show) => {
    otherContainer.classList.toggle("d-none", !show);
    if (!show) {
      otherField.value = "";
    }

    const labelToHide = selectorField.closest("div").querySelector(".radio-label");
    if (labelToHide) {
      labelToHide.classList.toggle("d-none", show);
    }
  };

  if (selectorField.nodeName.toLowerCase() === "select" && otherValue) {
    selectorField.addEventListener("change", () => {
      toggleOtherField(
        selectorField.selectedOptions.length &&
          selectorField.selectedOptions[0].value === otherValue,
      );
    });
  } else if (
    selectorField.nodeName.toLowerCase() === "input" &&
    selectorField.getAttribute("type") === "radio"
  ) {
    document
      .querySelectorAll(`input[name="${selectorField.name}"]`)
      .forEach((radio) => {
        radio.addEventListener("change", () => {
          if (radio.checked) {
            toggleOtherField(radio.id === selectorField.id);
          }
        });

        if (radio.checked) {
          toggleOtherField(radio.id === selectorField.id);
        }
      });
  }
}
