import Collapse from "bootstrap/js/dist/collapse";

import { generateElements, initTooltips } from "../core/utils";

const USER_CONTRIBUTED_PRICE_TOOLTIP_COPY =
  "This is an estimate based on user-submitted and/or publicly available data. It was not submitted by the business. Contact the business to confirm.";
const ESTIMATED_PRICE_TOOLTIP_COPY =
  "This is a typical price that is not specific to this business. Contact the business for actual pricing.";

export default class PriceList {
  constructor(props, table, filters, filterStatement) {
    this.table = table;
    this.tableRows = this.table.querySelectorAll(
      "tr:not(.pricing-total-row):not(.marketplace-pricing-row)",
    );

    // props
    this.arrowPrimaryPath = props.arrowPrimaryPath;
    this.arrowSecondaryPath = props.arrowSecondaryPath;
    this.blueCirclePath = props.blueCirclePath;
    this.canReceivePricingUpdateRequests = props.canReceivePricingUpdateRequests;
    this.caretPath = props.caretPath;
    this.filterInfo = props.priceFilters;
    this.hasEditedPrices = props.pricesEdited;
    this.requestPricingUpdatePath = props.requestPricingUpdatePath;
    this.services = props.priceList;
    this.yellowCirclePath = props.yellowCirclePath;

    this.filters = filters;
    this.filterStatement = filterStatement;

    this.estimatedCost = 0;
    this.registerHandlers();
    this.addTooltips();
  }

  registerHandlers() {
    this.filters.forEach((filter) => {
      filter.addEventListener("change", () => {
        // get the selected filter options
        const selectedCategory = this.filters[0].value;
        const selectedAge = this.filters[1].value;

        this.updateFilterStatement(selectedCategory, selectedAge);
        this.updateTable(selectedCategory, selectedAge);
        this.updateLegend();
      });
    });

    this.tableRows.forEach((tableRow) => {
      tableRow.addEventListener("click", (event) => {
        this.constructor.openDescription(event);
      });
    });
  }

  static openDescription(event) {
    Collapse.getOrCreateInstance(event.currentTarget.querySelector(".pricing-tip"), {
      toggle: false,
    }).toggle();
    event.currentTarget.querySelector("img").classList.toggle("rotated");
  }

  updateFilterStatement(selectedCategory, selectedAge) {
    let filterStatementText = `${this.hasEditedPrices ? "Official" : "Estimated"} prices for ${this.filterInfo[selectedCategory].copy} for `;
    if (selectedAge === "adult") {
      filterStatementText += "an Adult";
    } else if (selectedAge === "child") {
      filterStatementText += "a Child";
    } else if (selectedAge === "infant") {
      filterStatementText += "an Infant";
    }
    this.filterStatement.textContent = filterStatementText;
  }

  addTooltips() {
    this.table.querySelectorAll(".price-indicator").forEach((indicator) => {
      const secondaryCell = indicator.closest(".pricing-secondary-cell");
      secondaryCell.dataset.bsToggle = "tooltip";
      if (indicator.classList.contains("price-indicator-estimate")) {
        secondaryCell.dataset.bsTitle = ESTIMATED_PRICE_TOOLTIP_COPY;
      } else {
        secondaryCell.dataset.bsTitle = USER_CONTRIBUTED_PRICE_TOOLTIP_COPY;
      }
    });

    initTooltips();
  }

  updateLegend() {
    document
      .querySelector(".price-list-legend-average-pricing")
      .classList.toggle(
        "d-none",
        document.querySelectorAll(".price-indicator-estimate").length === 0,
      );

    document
      .querySelector(".price-list-legend-user-contributed")
      .classList.toggle(
        "d-none",
        document.querySelectorAll(".price-indicator-user-contributed").length === 0,
      );
  }

  // initiate the update
  updateTable(selectedCategory, selectedAge) {
    // get the services that are qualified for the selected filter combination
    const filteredServices = this.filterInfo[selectedCategory].services;

    // reset the table state
    this.emptyPriceTable();
    this.clearSum();

    // for each service for the set filters, insert a row in the table and
    // update the estimatedCost
    filteredServices.forEach((service) => {
      this.insertPriceRow(this.services[service], selectedAge);
      this.updateEstimatedCost(this.services[service].prices[selectedAge]);
    });

    this.insertEstimateRow();
    // update the table class member
    this.tableRows = this.table.querySelectorAll(
      "tr:not(.pricing-total-row):not(.marketplace-pricing-row)",
    );
    this.tableRows.forEach((tableRow) => {
      tableRow.addEventListener("click", (event) => {
        this.constructor.openDescription(event);
      });
    });

    this.addTooltips();
  }

  // empty the table
  emptyPriceTable() {
    this.table.querySelector("tbody").replaceChildren();
  }

  // update the estimated cost
  updateEstimatedCost(priceInfo) {
    this.estimatedCost += priceInfo.min;
  }

  // reset the sum
  clearSum() {
    this.estimatedCost = 0;
  }

  insertEstimateRow() {
    let firstCell = '<td class="align-middle">Total estimated cost';
    if (this.canReceivePricingUpdateRequests) {
      firstCell += `<br>
                    <a class="fw-normal" href="${this.requestPricingUpdatePath}">
                       Request exact pricing<img class="d-none d-md-inline ms-2" src="${this.arrowPrimaryPath}" width="20" height="15" alt="Right arrow"><span class="d-md-none">.</span>
                    </a>`;
    }

    firstCell += "</td>";

    this.table.querySelector("tbody").append(
      ...generateElements(
        `<tr class="pricing-total-row">
          ${firstCell}
          <td class="pricing-secondary-cell align-middle text-end">
            $${new window.Intl.NumberFormat("US").format(this.estimatedCost)} &amp; Up
          </td>
        </tr>`,
      ),
    );
  }

  // generate a row and append it to the price table
  insertPriceRow(serviceInfo, age) {
    let row = `<tr>
      <td class="pricing-primary-cell align-middle">
        <div class="pricing-title">
          <div>
            <img class="price-row-caret" src="${this.caretPath}" width="9" height="15" alt="Right caret">
            ${serviceInfo.name}
          </div>
        </div>
        <div class="pricing-tip collapse">${serviceInfo.description}</div>
      </td>
      <td class="pricing-secondary-cell align-middle">
        <div class="d-flex justify-content-end text-end">
      `;

    if (serviceInfo.prices[age].using_average_price) {
      row += `<img class="price-indicator price-indicator-estimate" src="${this.blueCirclePath}">`;
    } else if (serviceInfo.prices[age].user_contributed) {
      row += `<img class="price-indicator price-indicator-user-contributed" src="${this.yellowCirclePath}">`;
    }

    if (!serviceInfo.prices[age].min) {
      row += "N/A";
    } else {
      row += `$${new window.Intl.NumberFormat("US").format(serviceInfo.prices[age].min)}`;
      if (serviceInfo.prices[age].max) {
        row += ` -<br class="d-md-none">
                $${new window.Intl.NumberFormat("US").format(serviceInfo.prices[age].max)}`;
      }
    }

    row += `</div>
      </td>
    </tr>`;

    this.table.querySelector("tbody").append(...generateElements(row));

    // add a marketplace pricing row when needed
    if (!this.hasEditedPrices && serviceInfo.marketplace_data) {
      const minPrice = `$${new window.Intl.NumberFormat("US").format(serviceInfo.marketplace_data.min_price)}`;
      const maxPrice = `$${new window.Intl.NumberFormat("US").format(serviceInfo.marketplace_data.max_price)}`;
      this.table.querySelector("tbody").append(
        ...generateElements(
          `<tr class="marketplace-pricing-row">
            <td class="pricing-primary-cell align-middle">
              <div class="pricing-marketplace-message">
                Save!
                <a class="link-secondary" href="${serviceInfo.marketplace_data.category_url}" target="_blank">
                  Buy this on Ever Loved
                  <img src="${this.arrowSecondaryPath}" width="20" height="15" alt="Right arrow">
                </a>
              </div>
            </td>
            <td class="pricing-secondary-cell align-middle">
              <div class="marketplace-average-price text-end">
                ${minPrice} -<br class="d-md-none"> ${maxPrice}
              </div>
            </td>
          </tr>`,
        ),
      );
    }
  }
}
