import initReCAPTCHAs from "../core/reCAPTCHA";

export default function initReportBusiness(props) {
  document
    .querySelectorAll(`[name="${props.reasonCheckboxesName}"]`)
    .forEach((checkbox) => {
      const disclosure = document.querySelector(
        `[data-show-when-checked="${checkbox.id}"]`,
      );
      if (disclosure) {
        const handleCheckboxDisclosure = () => {
          disclosure.classList.toggle("d-none", !checkbox.checked);

          if (disclosure.dataset.shouldUncheck) {
            checkbox.checked = false;
          }
        };

        checkbox.addEventListener("change", handleCheckboxDisclosure);

        const dismissButton = disclosure.querySelector(".dismiss");
        if (dismissButton) {
          dismissButton.addEventListener("click", () => {
            disclosure.classList.add("d-none");
            checkbox.checked = false;
          });
        }

        handleCheckboxDisclosure();
      }
    });

  window.initReCAPTCHAs = initReCAPTCHAs;
}
