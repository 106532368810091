import Collapse from "bootstrap/js/dist/collapse";

import { initExpandableUserPosts, initUserPostForms } from "./UserPost";
import {
  animateCSS,
  getElementDocumentOffset,
  getNextSiblingMatchingSelector,
  scrollToElement,
  sleep,
} from "../core/utils";

/**
 * Initialize the community FAQs behavior -- collapses and success modal management.
 *
 * @param {object} props - the page props
 */
export default async function initCommunityFAQs(props) {
  if (!props.skipUserPostInit) {
    initUserPostForms();
  }

  // Expanding/collapsing additional answers
  const expandableFAQAnswers = document.querySelectorAll(".expandable-faq-answers");

  expandableFAQAnswers.forEach((answer) => {
    Collapse.getOrCreateInstance(answer);

    answer.addEventListener("shown.bs.collapse", () => {
      document
        .querySelector(
          `[data-bs-target="#${answer.getAttribute("id")}"].faq-answer-expander`,
        )
        .classList.add("d-none");
      document
        .querySelector(
          `[data-bs-target="#${answer.getAttribute("id")}"].faq-answer-collapser`,
        )
        .classList.remove("d-none");
      initExpandableUserPosts();
    });

    answer.addEventListener("hidden.bs.collapse", () => {
      document
        .querySelector(
          `[data-bs-target="#${answer.getAttribute("id")}"].faq-answer-expander`,
        )
        .classList.remove("d-none");
      document
        .querySelector(
          `[data-bs-target="#${answer.getAttribute("id")}"].faq-answer-collapser`,
        )
        .classList.add("d-none");
    });
  });

  // scroll to next question after submitting an answer, show success modal
  const searchParams = new URLSearchParams(window.location.search);
  const nextFAQ = searchParams.get("next_faq");
  const answerSuccessModal = document.querySelector(
    ".community-faq-answer-success-modal",
  );

  /**
   * Calculate the success modal positio, then show it, then dismiss it.
   */
  const displaySuccessModal = async () => {
    const bodyCardOffset = getElementDocumentOffset(
      document.querySelector(".body-card"),
    ).left;
    const stickyNavOffset = document
      .querySelector(".sticky-nav-wrapper")
      .getBoundingClientRect().height;
    let additionalOffset;

    if (bodyCardOffset) {
      additionalOffset = 40;
    } else {
      additionalOffset = 20; // no body-card margins on mobile, smaller offset
    }

    answerSuccessModal.style.right = bodyCardOffset + additionalOffset;
    answerSuccessModal.style.top = stickyNavOffset + additionalOffset;

    answerSuccessModal.classList.remove("d-none");
    animateCSS(answerSuccessModal, "fadeIn");
    await sleep(5000);
    await animateCSS(answerSuccessModal, "fadeOut");
    answerSuccessModal.classList.add("d-none");
  };

  if (nextFAQ) {
    // Scroll to the next question after waiting 100ms to get the correct position of the element

    // Wait a moment, then:
    // 1) Show the next FAQ if applicable (we wait so we get its laid out position)
    // 2) Show the success Modal (we wait so we can let the sticky nav hit on prior scroll)
    await sleep(100);
    const nextAnswerInput = document.getElementById(`id_faq_form-${nextFAQ}-answer`);
    const nextQuestion = getNextSiblingMatchingSelector(
      document.getElementById(`id_faq_form-${nextFAQ}-question_type`),
      ".faq-question-text",
    );
    const heightOffset =
      document.querySelector(".sticky-nav-wrapper").getBoundingClientRect().height +
      answerSuccessModal.getBoundingClientRect().height +
      40;

    if (nextQuestion) {
      scrollToElement(nextQuestion, -heightOffset);
      nextAnswerInput.focus();
      await sleep(400);
    }

    displaySuccessModal();
  }
}
