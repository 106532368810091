import PriceList from "../../components/CemeteryPriceList";
import initCustomerGuidance from "../customerGuidance";

export default function initCemetery(props) {
  if (!props.hasNoPriceList) {
    new PriceList(
      props,
      document.querySelector(".price-list-table"),
      document.querySelectorAll(".price-filter"),
      document.querySelector(".estimated-prices-statement"),
    );
  }

  initCustomerGuidance(props);
}
