import { generateElements } from "../core/utils";

export default function initEditBusinessFAQs(props) {
  let { formCount } = props;

  const removeFAQForm = (event) => {
    const form = document.getElementById("faq-form");
    const formsetFormContainer = event.currentTarget.closest(
      ".additional-faq-container",
    );

    if (formsetFormContainer.querySelector('input[type="hidden"]').value) {
      if (window.confirm("Are you sure you want to delete this question?")) {
        const toDelete = event.currentTarget.previousElementSibling;
        toDelete.checked = true;
        form.action = "?dr=1";
        form.submit();
      }
    } else {
      // Given the ID form field from the form we've removed, iterate over remaining forms
      // to ensure their index sequence in the formset is coalesced.
      const formIdRegex = /form-([0-9]+)-[A-Za-z_]+/;
      const removedIndex = parseInt(
        formsetFormContainer
          .querySelector('input[type="hidden"]')
          .getAttribute("name")
          .match(formIdRegex)[1],
        10,
      );

      formsetFormContainer.remove();

      if (removedIndex < formCount) {
        document.querySelectorAll(".additional-faq-container").forEach((_container) => {
          const containerIndex = parseInt(
            _container
              .querySelector('input[type="hidden"]')
              .getAttribute("name")
              .match(formIdRegex)[1],
            10,
          );
          if (containerIndex > removedIndex) {
            _container.querySelectorAll("input,textarea").forEach((input) => {
              if (input.name.includes(`-${containerIndex}-`)) {
                input.setAttribute(
                  "name",
                  input
                    .getAttribute("name")
                    .replace(`-${containerIndex}-`, `-${containerIndex - 1}-`),
                );
                input.setAttribute(
                  "id",
                  input
                    .getAttribute("id")
                    .replace(`-${containerIndex}-`, `-${containerIndex - 1}-`),
                );
              }
            });

            _container.querySelectorAll("label").forEach((label) => {
              if (label.getAttribute("for").includes(`-${containerIndex}-`)) {
                label.setAttribute(
                  "for",
                  label
                    .getAttribute("for")
                    .replace(`-${containerIndex}-`, `-${containerIndex - 1}-`),
                );
              }
            });
          }
        });
      }

      formCount -= 1;
    }
  };

  document.querySelector(".add-faq").addEventListener("click", () => {
    formCount += 1;

    const formsetContainer = document.querySelector(".edit-faq-form");

    formsetContainer.append(
      ...generateElements(props.emptyForm.replace(/__prefix__/g, formCount - 1)),
    );

    const newFAQForm = [
      ...formsetContainer.querySelectorAll(".additional-faq-container"),
    ].pop();

    newFAQForm.querySelector(".remove-faq").classList.remove("d-none");
    newFAQForm.querySelector(".delete-faq").addEventListener("click", removeFAQForm);

    document.querySelector("#id_form-TOTAL_FORMS").value = formCount;
  });

  document.querySelectorAll(".delete-faq").forEach((deleteBtn) => {
    deleteBtn.addEventListener("click", removeFAQForm);
  });
}
