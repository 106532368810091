import initReCAPTCHAs from "../core/reCAPTCHA";
import { initAnimatedPlaceholderFormFields } from "../core/utils";

export default function initRespondToCustomerLead() {
  const composeForm = document.querySelector("#compose-message");
  if (composeForm) {
    composeForm.addEventListener("submit", (event) => {
      event.preventDefault();
      event.stopPropagation();
    });

    document.body.addEventListener("hasEnrolled", () => {
      composeForm.submit();
    });
  }

  document.body.addEventListener("htmx:afterSettle", (evt) => {
    initAnimatedPlaceholderFormFields();
    evt.detail.elt.scrollIntoView(false);
    window.initReCAPTCHAs();
  });

  window.initReCAPTCHAs = initReCAPTCHAs;
}
