import initBusinessAutocomplete from "../../components/BusinessAutocomplete";
import requests, { ResponseError } from "../../core/requests";

/**
 * Initialize the funeral home review standalone page.
 *
 * @param {object} props - the page props.
 */
export default function initFuneralHomeReview(props) {
  const slugInput = document.getElementById(
    props.funeralHomeSearch.businessIdentifierInputId,
  );

  initBusinessAutocomplete(props.funeralHomeSearch);

  const funeralHomeContainer = document.querySelector(".fh-review-page");

  slugInput.tomselect.on("dropdown_open", () => {
    funeralHomeContainer.style.paddingBottom = "120px";
  });

  slugInput.tomselect.on("dropdown_close", () => {
    funeralHomeContainer.style.paddingBottom = "40px";
  });

  // here we grab the funeral home slug and pass that to the endpoint
  slugInput.addEventListener("change", async () => {
    const funeralHomeSlug = slugInput.tomselect.getValue();
    let funeralHomeInformation;
    try {
      const response = await requests.get(props.funeralHomeLookupUrl, {
        funeralHomeSlug,
      });

      if (!response.ok) {
        throw new ResponseError(response);
      }

      funeralHomeInformation = await response.json();
    } catch (err) {
      window.Rollbar.error("Funeral Home review funeral home lookup error", err);

      document.querySelector(".review-form-wrapper").classList.add("d-none");
      document.querySelector(".fh-name").classList.add("d-none");
      document.querySelector(".fh-location").classList.add("d-none");
      document.querySelector(".review-not-possible").style.display = "block";

      return;
    }

    // let's show the review form and fill in the
    // funeral home review page with the funeral home selection
    document.querySelector(".review-form-wrapper").classList.remove("d-none");
    const fhNameField = document.querySelector(".fh-name");
    fhNameField.classList.remove("d-none");
    fhNameField.innerText = funeralHomeInformation.funeralHomeName;

    const funeralHomeAddress = `${funeralHomeInformation.funeralHomeAddress}, ${funeralHomeInformation.funeralHomeCity}, ${funeralHomeInformation.funeralHomeState}, ${funeralHomeInformation.funeralHomeZip}`;
    document.querySelector(".fh-address").innerText = funeralHomeAddress;
    document.querySelector(".fh-location").classList.remove("d-none");

    // when we submit the form we want to pass the correct data to
    // view, in case the form errors out.
    document.getElementById("_funeral_home_name").value =
      funeralHomeInformation.funeralHomeName;
    document.getElementById("_funeral_home_address").value = funeralHomeAddress;
    document.getElementById("_funeral_home_slug").value =
      funeralHomeInformation.funeralHomeSlug;

    document.querySelector(".review-not-possible").style.display = "none";
  });
}
