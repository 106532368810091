import Modal from "bootstrap/js/dist/modal";

import initReCAPTCHAs from "../core/reCAPTCHA";
import { sendBrowserAgnosticEvent } from "../core/utils";

export default function initContributeUserPrices() {
  const warningModal = document.querySelector(
    "#user-contributed-price-invalid-warning-modal",
  );
  if (warningModal) {
    const editPrices = warningModal.querySelector(
      "#user-contributed-price-invalid-warning-modal-edit-prices",
    );
    if (editPrices) {
      editPrices.addEventListener("click", () => {
        sendBrowserAgnosticEvent(
          document.querySelector(".contribute-user-prices-form"),
          "cancel-submit-protect",
        );
      });
    }
  }

  const initPriceEntry = (root) => {
    // Init recaptchas if the new HTMX-loaded HTML is/has a recaptcha form.
    if (root.classList.contains("g-recaptcha-form")) {
      initReCAPTCHAs();
    }

    // Check any inputs for values < 10
    root.addEventListener("htmx:beforeRequest", (e) => {
      if (
        warningModal &&
        Array.from(root.querySelectorAll('[type="number"]')).some(
          (numberInput) => numberInput.value && parseInt(numberInput.value, 10) < 10,
        )
      ) {
        if (
          !(
            e.detail.requestConfig.triggeringEvent &&
            e.detail.requestConfig.triggeringEvent.submitter &&
            e.detail.requestConfig.triggeringEvent.submitter.getAttribute("name") ===
              "submit-anyway"
          )
        ) {
          Modal.getOrCreateInstance(warningModal).show();
          e.preventDefault();
        }
      }
    });
  };

  document.body.addEventListener("htmx:afterSettle", (evt) => {
    initPriceEntry(evt.detail.elt);
  });

  initPriceEntry(document.querySelector(".contribute-user-prices-form"));
}
